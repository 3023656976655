.company-input-container {
  width: 98%;
  border-radius: 10px;
  /* height: 58vh; */
  background-color: white;
  /* border-radius: 1rem; */
  /* background: linear-gradient(45deg, #c5c5c5 0%, #ffffff 100%); */
  /* padding: 0.3rem; */
  justify-content: space-between;
  margin: 1.5%;
  padding: 1%;
}

.company-sub-input-container {
  margin: 3%;
}
.company-container-scroll{
  max-height: 70vh;
  overflow-y: auto;
}

.company-inner_div {
  justify-content: flex-end;
  display: flex;
  /* margin-top: 15px; */
}

.company-button-style {
  color: #fff;
  font-weight: 500;
  padding: 10px 30px;
  box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border-color: #fff;
}

.company-button-style:hover {
  transform: scale(1.1);
  animation-duration: 0.2s;
  transition: ease;
}

@media (max-width: 500px) {
  .company-input-container {
    flex-direction: column;
  }
}

tr {
  border: 1px solid #ddd;
  color: black;
  /* padding: 8px; */
}

tr:nth-child(even) {
  background-color: #fff;
  color: #000;
  background-color: #f6f6f6;
}
tr:nth-child(odd) {
  background-color: #fff;
  background-color: #e9e9e9;
  color: #000;
}

tr:hover {
  background-color: #ddd;
}

.th {
  /* padding-top: 12px;
  padding-bottom: 12px; */
  text-align: left;
  background-color: #219bcc;
  color: white;
  /* padding: 12px; */
}
td {
  text-align: left;
  color: #000;
  /* padding: 12px; */
}

.company-table {
  margin-top: 5%;
}
