.group {
  display: flex;
  line-height: 30px;
  align-items: center;
  position: relative;
  /* max-width: 200px; */
  border-color: #637091;
  width: 100%;
  transition: 0.3s;
  transform: scale(1.01);
}

.text-box-style {
  width: 100%;
  height: 25px;
  line-height: 30px;
  padding: 1.2rem;
  /* padding: 5px; */
  padding-left: 1rem;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 10px;
  outline: none;
  background-color: #ededed;
  /* border-color: #637091; */
  color: #0d0c22;
  transition: 0.5s ease;
}

.text-box-style::placeholder {
  color: #94a3b8;
}

.text-box-style:focus,
.input:hover {
  outline: none;
  /* border-color: #637091; */
  background-color: #ededed;
}

.text-box-icon-style {
  position: absolute;
  /* background-color: red; */
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  height: 34px;
  width: 5px;
}

.text-error {
  outline: none;
  border-color: red;
}
.helper-text {
  color: red;
  font-size: 12px;
}
